exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-esports-tsx": () => import("./../../../src/pages/esports.tsx" /* webpackChunkName: "component---src-pages-esports-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-templates-project-tsx-content-file-path-codebuild-output-src-966298192-src-job-files-portfolio-src-data-writeups-gopheresports-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/codebuild/output/src966298192/src/job-files/portfolio/src/data/writeups/gopheresports.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-codebuild-output-src-966298192-src-job-files-portfolio-src-data-writeups-gopheresports-mdx" */),
  "component---src-templates-project-tsx-content-file-path-codebuild-output-src-966298192-src-job-files-portfolio-src-data-writeups-mncs-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/codebuild/output/src966298192/src/job-files/portfolio/src/data/writeups/mncs.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-codebuild-output-src-966298192-src-job-files-portfolio-src-data-writeups-mncs-mdx" */),
  "component---src-templates-project-tsx-content-file-path-codebuild-output-src-966298192-src-job-files-portfolio-src-data-writeups-rocketcast-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/codebuild/output/src966298192/src/job-files/portfolio/src/data/writeups/rocketcast.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-codebuild-output-src-966298192-src-job-files-portfolio-src-data-writeups-rocketcast-mdx" */)
}

